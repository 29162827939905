// GSAP Animations

(function ($) {
  'use strict';
  $(document).ready(function() {
    if ($('body').hasClass('home')) {
      gsap.to('.hero-form-headline', {
        duration: 1,
        delay: 0.4,
        opacity: 1,
        ease: 'power2.out'
      });
      gsap.to('.hero-form-text', {
        duration: 1,
        delay: 1,
        opacity: 1,
        ease: 'power1.out'
      });
      gsap.to('.btn-finance', {
        duration: 1.5,
        delay: 2.4,
        right: '-6px',
        ease: 'power1.inOut'
      });
      gsap.to('.hero-form > div', {
        duration: 1,
        delay: 2,
        opacity: 1,
        ease: 'power1.out'
      });
    } else if ($('body').hasClass('page-template-gallery')) {
      gsap.from('.featured-thumbnail', {
        duration: 1.5,
        y: -500,
        ease: 'power1.inOut'
      });
      gsap.to('.featured-thumbnail', {
        duration: 0.5,
        opacity: 1,
        ease: 'power1.in'
      });
      gsap.to('.the-gallery-title h2', {
        duration: 1,
        delay: 1,
        opacity: 1
      });
      gsap.to('.gallery-img-item', {
        duration: 1.5,
        delay: 1.5,
        opacity: 1
      });
    } else {
      gsap.from('.featured-thumbnail', {
        duration: 1.5,
        y: -500,
        ease: 'power1.inOut'
      });
      gsap.to('.featured-thumbnail', {
        duration: 0.5,
        opacity: 1,
        ease: 'power1.in'
      });
      gsap.to('.entry-content.fadeIn', {
        duration: 1.5,
        delay: 1.5,
        opacity: 1
      });
    }

    // Navbar Animations
    let didScroll;
    let lastScrollTop = 0;
    let delta = 15;
    let navbarHeight = $('#site-navbar').outerHeight();
    
    $(window).scroll( function(e) {
      didScroll = true;
    } );
    setInterval( () => {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250 );

    function hasScrolled() {
      let st = $(window).scrollTop();
      let wh = $(window).height();
      // On Scroll Down
      if (st > lastScrollTop) {
        // check if halway down
        if (st < wh / 2) {
          // no animation
          let navPos = $('#site-navbar').css('position');
          if (navPos === 'absolute') {
            return;
          } else {
            $('#site-navbar').removeClass('navScroll');
          }
        } else {
          // set for animation
          if ($('#site-navbar').hasClass('navScroll')) {
            gsap.to('#site-navbar', {
              duration: 0.6,
              top: -150,
              ease: 'power1.in'
            });
          } else {
            gsap.set('#site-navbar', { top: -150 });
            $('#site-navbar').addClass('navScroll');
            
          }
        }
        lastScrollTop = st;
      }
      // On Scroll Up
      if (st < lastScrollTop - 1) {
        // Animate
        gsap.to('#site-navbar', {
          duration: 0.5,
          top: 0,
          ease: 'power1.in'
        });
        lastScrollTop = st;
        if (st < 49) {
          $('#site-navbar').removeClass('navScroll');
        }
      }
    }
    
  });
}(jQuery));